/* tanstack table body css class */

.headless_tbody {
  @apply relative border-b border-foreground-200 whitespace-nowrap text-base font-medium;
}

.headless_grid {
  @apply grid;
}

.headless_table-row-group {
  @apply table-row-group;
}

.headless_table-row {
  @apply table-row;
}

.headless_table-cell {
  @apply table-cell;
}

.headless_min-h-100 {
  @apply min-h-[100];
}

.headless_td-empty-rows {
  @apply m-0 italic text-foreground text-center w-full py-8 font-normal text-base leading-normal tracking-[0.00938em];
  @apply max-w-[680px] mx-auto  pt-[4rem] px-4 whitespace-normal;
}

.headless_items-center {
  @apply items-center;
}

.headless_tbody-td {
  @apply cursor-pointer border-default-500/10 text-left font-normal whitespace-normal leading-normal overflow-visible tracking-[0.01071em] text-foreground  hover:outline-offset-[-1px] hover:text-clip text-[0.825rem] !min-w-[250px] md:!min-w-[50px];
}

.headless_text-ellipsis {
  @apply text-ellipsis;
}

.headless_hover-outline-1 {
  @apply hover:outline-1;
}

.headless_hover-outline {
  @apply hover:outline;
}
.headless_hover-outline-default-color {
  @apply hover:outline-default-500/30;
}

.headless_p-0_05 {
  @apply p-[0_0.5rem];
}
.headless_p-05 {
  @apply p-[0.5rem];
}
.headless_p-1 {
  @apply p-[1rem];
}

.headless_p-05_075 {
  @apply p-[0.5rem_0.75rem];
}

.headless_p-1_125 {
  @apply p-[1rem_1.25rem];
}

.headless_p-15 {
  @apply p-[1.5rem];
}

.headless_z-3 {
  @apply z-[3];
}

.headless_z-2 {
  @apply z-[2];
}

.headless_z-1 {
  @apply z-[1];
}

.headless_z-0 {
  @apply z-0;
}

.headless_cursor-pointer {
  @apply cursor-pointer;
}
.headless_cursor-inherit {
  @apply cursor-[inherit];
}
.headless_cell_group_icon {
  @apply flex justify-start items-center px-2;
}

.headless_cell_value_span {
  @apply bg-warning/[0.5] rounded-sm text-black py-[2px] px-[1px];
}

.headless_tbody_tr {
  @apply bg-content1 box-border w-full hover:bg-default-100/80 cursor-pointer;
}

.headless_flex {
  @apply flex;
}

.headless_opacity-40 {
  @apply opacity-40;
}

.headless_opacity-50 {
  @apply opacity-50;
}

.headless_opacity-100 {
  @apply opacity-100;
}

.headless_vertual_row {
  @apply absolute transition-all ease-in-out delay-150 top-0;
}

.headless_transition-none {
  @apply transition-none;
}
.headless_tbody_tr_selected:hover td {
  @apply bg-primary/[0.2];
}

.headless_w-full {
  @apply w-full;
}

.headless_renderDetail {
  @apply min-h-[0] h-auto overflow-visible;
}
.headless_renderDetail-child {
  @apply flex w-full;
}

.headless_py-4 {
  @apply py-4;
}

.headless_renderDetail-td {
  @apply transition ease-in-out delay-150 text-left text-base;
  /* border-b border-solid border-default-500/10 */
}

/* columnPinning button class */

.headless_columnpin {
  @apply min-w-[70px] text-center;
}

.headless_tooltip_bottom {
  @apply inline-flex align-middle relative -top-[2px];
}

.headless_btn_transparent {
  @apply bg-transparent;
}

.headless_btn_primary {
}

.headless_editActtion_button {
  @apply flex gap-3;
}

.headless_hw-175 {
  @apply h-[1.75rem] w-[1.75rem];
}

.headless_hw-225 {
  @apply w-[2.25rem] h-[2.25rem];
}

.headless_expandBtn-tooltipBtn {
  @apply bg-transparent flex items-center justify-center;
}

.headless_expandbtn-btn-wh {
  @apply w-[2.25rem] h-[2.25rem] mt-[-0.25rem];
}

.headless_grabhandle-btn {
  @apply hover:bg-transparent active:cursor-grabbing cursor-grab m-[0-0.1rem] hover:opacity-100 opacity-50 p-[2px] transition ease-in-out delay-150 p-0 focus-visible:outline-none;
}

.headless_showhide-column {
  @apply relative inline-block text-left;
}

.headless_showhidecolumn-menu {
  @apply absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 font-mono;
}

.headless_columnMenu-div {
  @apply py-1;
}

.headless_tfoot {
  @apply z-[1] sticky bottom-0 opacity-[0.97] border-foreground-300;
}

.headless_border-foreground {
  @apply border-foreground-700;
}

.headless_border-b-solid {
  @apply border-b border-solid;
}

.headless_tfoot-td {
  @apply border-y border-default-500/10 font-bold whitespace-nowrap text-base align-top;
}

.headless_justiText-center {
  @apply text-center justify-center;
}

.headless_justify-center {
  @apply justify-center;
}

.headless_justify-between {
  @apply justify-between;
}

.headless_textLeft {
  @apply text-left;
}

.headless_p-2 {
  @apply p-2;
}
.headless_p-3 {
  @apply p-3;
}
.headless_p-4 {
  @apply p-4;
}
.headless_p-6 {
  @apply p-6;
}
.headless_p-1_125 {
  @apply p-[1rem,1.25rem];
}

.headless_pb-04 {
  @apply pb-[0.4rem];
}

.headless_pb-06 {
  @apply pb-[0.6rem];
}
.headless_pb-08 {
  @apply pb-[0.75rem];
}

.headless_pt-2 {
  @apply pt-2;
}

.headless_pt-3 {
  @apply pt-3;
}

.headless_pt-5 {
  @apply pt-5;
}

.headless_tfoot-row {
  @apply bg-content1 w-full align-middle outline-0;
}

.headless_thead {
  @apply !sticky top-[60px]   bg-white z-[1];
}

@media (max-width: 767px) {
  .headless_thead {
    top: 0px !important;
  }
}

.headless_sticky-z-2 {
  @apply sticky z-[2];
}

.headless_relative {
  @apply relative;
}

.headless_top-0 {
  @apply top-0;
}

.headless_thead-td {
  @apply border-default-500/50 whitespace-nowrap text-base font-bold overflow-visible text-default leading-[3.2] border-b border-borderGray align-middle;
}
.headless_thead-td.Sorted {
  @apply text-black;
}

.headless_text-center {
  @apply text-center;
}

.headless_flex-col {
  @apply flex-col;
}

.headless_flex-row {
  @apply flex-row;
}

.headless_center-flex-gap1 {
  @apply items-center flex;
}
.headless_center-flex-gap1 > .headless_text-ellipsis {
  @apply pr-0;
}
.headless_flex-row-reverse {
  @apply flex-row-reverse;
}

.headless_thead-div {
  @apply items-center flex relative w-full text-[11px] justify-center;
}

.headless_overflow-visible {
  @apply overflow-visible;
}

.headless_whitespace-nowrap {
  @apply whitespace-nowrap;
}

.headless_flex-center {
  @apply flex justify-center items-center;
}

.headless_thead-cellgrab {
  @apply whitespace-nowrap flex items-center justify-center;
}

.headless_cellAction-btn {
  @apply absolute z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none font-mono;
}

.headless_left-0 {
  @apply left-0;
}

.headless_right-0 {
  @apply right-0;
}

.headless_thead-resizehandle {
  @apply cursor-col-resize  px-1;
}

.headless_thead-resizehandle > hr {
  @apply opacity-100 active:border-primary;
}

.headless_mr-075 {
  @apply mr-[-0.75rem];
}

.headless_mr-1 {
  @apply mr-[-1rem];
}

.headless_resizehandle-hr {
  @apply h-6 select-none rounded-sm border-2 touch-none z-[4];
}

.headless_resizehandle-hr {
  @apply h-6 select-none rounded-sm border-2 touch-none z-[4];
}

.headless_resizehandle-hr2 {
  @apply transition ease-in-out delay-150;
}

.headless_sorticon-btn {
  @apply bg-transparent w-auto ml-1 lg:pl-0 h-auto flex items-center;
}

.headless_translate-x-05 {
  @apply translate-x-[-0.5ch];
}

.headless_thead-row {
  @apply top-0 bg-content1 shadow-[4px_0_8px_black/10];
}

.headless_filter-range {
  @apply grid gap-4 grid-cols-[1fr_1fr];
}

.headless_relative-mt-2 {
  @apply relative mt-2;
}

.headless_filter-input {
  @apply border-solid border-b border-default-500/40  font-normal w-[100%] p-0 m-0 mx-[-2px] whitespace-nowrap text-ellipsis text-[1rem] leading-[0.8rem] block w-full border-0 leading-6 py-1.5 focus:ring-0 focus-visible:border-default-500 focus-visible:outline-none focus-visible:border-b-[2px];
}

.headless_filter-input > .MuiSelect-icon {
  @apply mr-[-2px];
}

.headless_w-0 {
  @apply w-0;
}

.headless_min-w-100 {
  @apply min-w-[100px];
}

.headless_min-w-120 {
  @apply min-w-[120px];
}

.headless_w-auto {
  @apply w-auto;
}

.headless_filter-text-div {
  @apply flex items-center absolute py-1.5 top-0 right-0;
}

.headless_flex-wrap-gap {
  @apply flex flex-wrap gap-0.5;
}

.headless_filter-text-btn {
  @apply rounded-full bg-foreground-600 p-1.5 text-foreground shadow-sm hover:bg-foreground-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-foreground-600;
}

.headless_flex-m-g2 {
  @apply flex m-0 items-center gap-2;
}

.headless_filter-text-checkbox {
  @apply mr-2 h-4 w-4 rounded border-foreground-300 text-indigo-600 focus:ring-indigo-600;
}

.headless_globalFilter {
  @apply relative min-w-[240px] flex items-center justify-between border-b;
}

.headless_globalFilter-input {
  @apply max-w-xs h-full focus:ring-0 focus-visible:border-primary focus-visible:outline-none;
}

.headless_dropdown-menu {
  @apply bg-foreground-100 text-black font-normal bg-content1 block py-2 px-4 text-[1rem];
}

.headless_dropdownMenu-divider {
  @apply border-b border-solid border-black/20;
}

.headless_columnAction-btn {
  @apply block px-4 py-2 text-base rounded-none hover:!bg-default/60 w-full justify-start bg-transparent;
}

.headless_columnAction-btn2 {
  @apply block px-4 py-2 text-base rounded-none w-full justify-start bg-transparent;
}

.headless_columnAction-btn3 {
  @apply border-b border-solid border-foreground rounded-none;
}

.headless_grouping-btn {
  @apply block px-4 py-2 text-base hover:!bg-default/60 w-full justify-start bg-transparent;
}

.headless_Action-columnBtn {
  @apply cursor-pointer hover:!bg-default/60;
}

.headless_action-grouping {
  @apply block px-4 py-2 text-base w-full justify-start bg-transparent;
}

.headless_grouping-btn4 {
  @apply border-b border-solid border-foreground;
}

.headless_actionMenu-columnGrp {
  @apply block px-4 py-2 text-base cursor-pointer hover:!bg-default/60 rounded-none w-full justify-start bg-transparent;
}

.headless_showHide-column {
  @apply flex justify-evenly p-2;
}

.headless_showHide-column-btn {
  @apply no-underline text-base hover:no-underline;
}

.headless_SH-column-div {
  @apply relative inset-0 flex items-center;
}

.headless_SH-column-child {
  @apply w-full border-t border-foreground-300;
}

.headless_showHide-columnItem {
  @apply outline-offset-[-2px] flex items-center justify-start hover:bg-default-500/10;
}

.headless_columnItem-drag {
  @apply opacity-50 outline-2 outline-dashed outline-default-500/10;
}

.headless_columnItem-hover {
  @apply outline-2 outline-dashed outline-primary;
}

.headless_columnItem-child {
  @apply flex flex-nowrap gap-2 my-0 py-3;
}

.headless_w-70 {
  @apply w-[70px];
}

.headless_column-checkbox {
  @apply gap-2 me-2;
}

.headless_coulumMenu-text {
  @apply m-0 font-normal text-[16px] leading-normal self-center;
}

.headless_table-class {
  @apply w-full p-0 m-0 font-sans font-primary;
}
.headless_table {
  @apply table border-spacing-0 border-separate;
}

.headless_table-fixed {
  @apply table-fixed;
}

.headless_table-container {
  @apply max-w-[100%] min-h-[200px];
}

.headless_table-stickyHead {
  @apply max-h-[100%] !overflow-auto;
}

.headless_table-paper {
  @apply bg-content1 w-full h-full transition ease-in-out delay-100 rounded overflow-visible shadow-[0px_3px_1px_-2px_black/20,_0px_2px_2px_0px_black/[0.14],_0px_1px_5px_0px_black/[0.12]] relative;
}
.headless_table-paper .loading-root {
  @apply max-h-[calc(100%-51px)];
}
.headless_bottom-toolbar {
  @apply left-0 right-0 shadow-[0_1px_2px_-1px_black/0.1] flex items-center justify-center bg-gray-950 bottom-0;
  /*sticky*/
}

.headless_bottom-fixed {
  @apply bottom-0 fixed;
}

.headless_bottom-child {
  @apply flex items-center justify-center sm:justify-between w-full box-border bg-gray-100 font-medium;
}

.headless_bottom-toolbarAction {
  @apply flex justify-center sm:justify-end right-0 top-0 overflow-visible relative w-full;
  /*md:absolute*/
}

.headless_pagination-main {
  @apply flex items-center justify-end w-full bg-white border-t border-solid border-borderGray;
}

.headless_pagination-child1 {
  @apply flex-row-reverse flex items-center min-h-[60px] mx-2.5 text-base text-foreground gap-5 justify-between md:justify-center md:gap-0 w-full;
}

.headless_flex-gap {
  @apply flex justify-center items-center gap-3;
}

.headless_pagination-select {
  @apply max-w-xs p-2.5;
}

.headless_pagination-page {
  @apply flex items-center gap-1 justify-center order-1;
}

.headless_badge {
  @apply text-white p-4 pr-2  gap-2;
}

.headless_badge-crossIcone {
  @apply cursor-pointer hover:fill-white;
}

.headless_multi-badges {
  @apply bg-gray-100 rounded-none text-[1rem] left-0 p-0 relative right-0 top-0 w-full z-[2];
}

.headless_mb-0 {
  @apply mb-0;
}

.headless_mb--1 {
  @apply mb-[-1rem];
}

.headless_multi-badgeItem {
  @apply flex-1 md:flex md:justify-between text-left;
}

.headless_py-2 {
  @apply py-2;
}

.headless_p-05_1 {
  @apply p-[0.5rem_1rem];
}

.headless_dropzone {
  @apply flex h-full absolute w-full z-[4] justify-center items-center  backdrop-sepia-[4px] border-dashed border-2 border-primary;
}

.headless_bg-primary {
  @apply bg-primary;
}

.headless_tInternal-btn {
  @apply flex gap-2 items-center z-[3];
}

.headless_topToolbar {
  @apply !overflow-visible relative;
}

.headless_top-sticky {
  @apply top-0 sticky;
}

.headless_toolbar-item {
  @apply flex box-border justify-between items-start p-2 right-0 top-0 w-full bg-gray-100;
}

.headless_stackAlert {
  @apply md:absolute;
}

.headless_top-toolbar-filter {
  @apply flex justify-end flex-wrap-reverse;
}
.headless_img {
  @apply h-8 w-8;
}
.headless_img_item {
  @apply h-full w-full object-contain;
}
.headless_img.scs_image {
  @apply flex items-center justify-center;
}
.headless_img.scs_image img {
  @apply w-[22px] h-auto mx-auto;
}
.headless_rightSticky {
  @apply sticky !z-0 right-0;
}
.headless_tbody_tr_expanded {
  @apply bg-default-100/80;
}
/* Custom */
/* .headless_thead-td:first-child,
.headless_tbody-td:first-child {
  @apply !w-[22px]
} */
/* .headless_rightSticky,
.headless_thead-td:last-child{
  @apply !w-[22px];
} */
.headless_tbody_tr:hover .headless_rightSticky,
.headless_tbody_tr_expanded .headless_rightSticky,
.headless_tbody_tr:nth-child(odd):hover .headless_rightSticky {
  @apply bg-tableRowHover transition-all;
}
.headless_tbody_tr.headless_tbody_tr_expanded {
  @apply bg-tableRowHover;
}
.headless_thead {
  /* @apply  shadow-[0px_0px_0px_1px] shadow-border */
  @apply border-b border-border;
}
.headless_tbody_tr:nth-child(odd) {
  @apply bg-tableRow hover:bg-tableRowHover;
}
.headless_tbody_tr_expanded,
.headless_tbody_tr:hover {
  @apply !bg-tableRowHover;
}
.headless_tbody-td > div {
  @apply flex justify-center text-center relative;
}
.headless_renderDetail-child {
  @apply max-w-site mx-auto;
}
.mobile_table_data {
  @apply md:w-full md:flex md:flex-col;
}
.headless_tbody-td .shape_image_wrap {
  @apply md:h-full md:w-full md:flex-wrap;
}
.headless_tbody-td {
  @apply font-medium xs:p-1.5 xs:text-xs;
}
.headless_tbody-td:first-child,
.headless_tbody-td.isNull:first-child + td {
  @apply md:align-top;
  /* @apply sm:!w-[60px]; */
}
.headless_tbody-td:last-child {
  /* @apply sm:!w-[90px] xs:!w-[75px];
  @apply md:pr-3 sm:pr-1.5 md:text-xs sm:text-sm xs:text-xs; */
}
.headless_tbody-td:not(:first-child):not(:last-child) {
  /* @apply sm:!w-[150px]; */
}
.headless_tbody-td:last-child > div {
  /* @apply xs:justify-center sm:justify-center md:justify-end md:flex-wrap; */
}
.headless_tbody-td:last-child > div > .compare {
  @apply inline-flex items-center gap-1.5 text-sm mt-auto;
}
.mobile_table_data_items {
  @apply flex justify-between w-full md:py-1 md:border-b md:border-borderGray md:last:border-b-0 items-center;
}
.mobile_table_data_label {
  @apply capitalize text-default;
}
.headless_tbody-td .headless_img {
  @apply md:h-full md:w-full md:pt-[40px] sm:pt-[50%] relative;
}
.headless_tbody-td .headless_img > .headless_img_item {
  @apply md:absolute md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2;
}
.headless_tbody-td .headless_mobile_onlytitle_shape {
  @apply py-1 leading-normal;
}

.headless_sorticon-btn svg {
  @apply !h-[1.5em] !w-[1.5em];
}
.headless_overflow-hidden {
  @apply overflow-hidden;
}
.comparison.mobile_table_data_items {
  @apply pt-3 text-base sm:text-sm;
}
.comparison.mobile_table_data_items > .mobile_table_data_label {
  @apply w-full text-left cursor-pointer text-black;
}
.headless_tbody-td:last-child > div > .comparison {
  @apply inline-flex items-center gap-1.5 text-sm mt-auto;
}
.headless_tbody-td input[type="checkbox"] {
  @apply size-5;
  /*md:size-4*/
}
/*Grouping for recommendation*/
.headless_tbody_tr.headless_tbody_grouping > td:first-child > div {
  @apply cursor-text;
}
.headless_tbody_tr.headless_tbody_grouping {
  @apply cursor-text h-8 !bg-groupingBorder relative;
}
.headless_tbody_tr.headless_tbody_grouping > td:first-child {
  @apply !flex absolute !w-full h-full items-center py-0 font-semibold justify-center text-black;
}
.headless_tbody_tr.headless_tbody_grouping > td:not(:first-child) {
  @apply !w-0 !hidden !max-w-0 !min-w-0 !overflow-hidden !p-0;
}
/*Bordered layout*/
.headless_tbody_tr.headless_tbody_grouping > td:first-child {
  @apply border-l-4 border-r-4 border-solid border-groupingBorder;
  /*border-right-color: rgb(230 197 207);*/
}
.headless_tbody_grouping ~ tr > .headless_tbody-td:first-child,
.headless_tbody_grouping ~ tr > .headless_tbody-td.isNull:first-child + td {
  @apply border-l-4 border-solid border-groupingBorder;
}
.headless_tbody_grouping
  ~ tr:not(.headless_tbody_grouping)
  > .headless_tbody-td.isNull:first-child {
  /* @apply md:!hidden; */
}
.headless_tbody_grouping ~ tr > .headless_tbody-td:last-child {
  @apply border-r-4 border-solid border-groupingBorder;
}
.headless_tbody_grouping ~ tr:last-child > td {
  @apply border-b-4 border-solid border-groupingBorder;
}
.headless_thead-row > .headless_thead-td.isNull:first-child {
  /*@apply !hidden;*/
  @apply !w-0 p-0;
}
.headless_tbody_grouping ~ tr > .headless_tbody-td {
  @apply md:align-top;
}

.headless_tbody_tr.headless_tbody_grouping ~ .headless_tbody_grouping > td {
  @apply p-0 border-t-[25px] border-t-[white] border-x-0 border-solid;
}
.headless_tbody_tr.headless_tbody_grouping ~ .headless_tbody_grouping:before {
  @apply content-[""] bg-groupingBorder w-full h-1 flex absolute z-[1] top-0;
}
.headless_tbody_tr.headless_tbody_grouping ~ .headless_tbody_grouping {
  @apply h-[calc(2rem+25px)];
  /*@apply h-12;*/
}
/*Change table layout with flex only for grouping*/
@media screen and (max-width: 767px) {
  .headless_tbody_grouping ~ tr > .headless_tbody-td:nth-last-child(2) {
    @apply align-top;
    /* !min-w-[300px] w-[300px] sm:!min-w-[200px]; */
  }
  .headless_tbody_grouping ~ tr {
    /* @apply flex justify-between; */
  }
  .headless_tbody_grouping ~ tr > td {
    @apply !table-cell;
  }
  .headless_tbody_grouping
    ~ tr:not(.headless_tbody_grouping)
    > .headless_tbody-td.isNull
    + td,
  .headless_tbody_grouping ~ tr > .headless_tbody-td:last-child {
    @apply flex-1 !min-w-[60px] !w-auto;
  }
  .headless_tbody_grouping
    ~ tr:not(.headless_tbody_grouping)
    > .headless_tbody-td
    .headless_img {
    @apply h-[40px] sm:pt-[30px] xs:pt-[50%];
    height: auto;
  }
  .headless_tbody_grouping
    ~ tr:not(.headless_tbody_grouping)
    > .headless_tbody-td
    .shape_image_wrap {
    @apply h-auto;
  }
}
.headless_border-l-solid {
  @apply border-l border-solid;
}
.headless_border {
  @apply border border-solid;
}
.headless_px-4 {
  @apply px-4;
}
.headless_py-2 {
  @apply py-2;
}
